import config from "react-global-configuration"
import { all, call, put, takeLatest } from "redux-saga/effects"

import { getMedicalPath, getNextDoctor } from "client/optic/saga"
import { getCall } from "containers/Call/services/actions"
import stripeActions from "containers/Stripe/services/actions"
import { languages } from "lib/languages"
import { request } from "lib/request"
import { DoctorType } from "types/entity"
import { ERROR_API } from "types/error"
import {
  Call,
  OpticConsultationRequest,
  ResponseCall,
  ResponsePrice,
} from "types/payload"
import { Action, GFlow, GWatcher, Message } from "types/redux"

import actions from "./actions"
import { PRICE_REQUEST, TLC_REQUEST } from "./constants"

async function createConsultationApi(
  payload: OpticConsultationRequest
): Promise<ResponseCall> {
  const opticConsultationUrl = config.get("optic.post.consultation")
  return await request(opticConsultationUrl, {
    method: "POST",
    payload,
  })
}

async function getPriceApi(payload: {
  relativeId?: string
  callType: DoctorType
}): Promise<ResponseCall> {
  return request(
    `${config.get("call.get.price")}` +
    `?final=true` +
    `&call_type_value=${payload.callType}` +
    `&relative_id=${payload.relativeId}`,
    {
      method: "GET",
    }
  )
}

async function getPricesApi(payload: { relativeId?: string }) {
  const orthoptist = await request(
    `${config.get("call.get.price")}` +
    `?&call_type_value=${DoctorType.ORTHOPTIST}` +
    `${payload.relativeId ? `&relative_id=${payload.relativeId}` : ``}`,
    {
      method: "GET",
    }
  )

  const ophtalmologist = await request(
    `${config.get("call.get.price")}` +
    `?&call_type_value=${DoctorType.OPHTALMOLOGIST}` +
    `${payload.relativeId ? `&relative_id=${payload.relativeId}` : ``}`,
    {
      method: "GET",
    }
  )
  return Promise.resolve({
    orthoptist,
    ophtalmologist,
  })
}

function* createConsultation(
  payload: OpticConsultationRequest
): GFlow<ResponseCall> {
  const medicalPathResponse = yield call(getMedicalPath)
  const nextDoctorCategory =
    getNextDoctor(medicalPathResponse) || DoctorType.ORTHOPTIST
  return yield call(createConsultationApi, {
    ...payload,
    call_type: nextDoctorCategory,
  } as OpticConsultationRequest)
}

function* priceFlow({
  payload,
}: Action<{ relativeId?: string }>): GFlow<ResponsePrice | Message> {
  try {
    // const medicalPathResponse = yield call(getMedicalPath)
    // const nextDoctorCategory: DoctorType =
    //   getNextDoctor(medicalPathResponse) || DoctorType.ORTHOPTIST
    // const response: ResponsePrice = yield call(getPriceApi, {
    //   ...payload,
    //   callType: nextDoctorCategory,
    // })
    const response = yield getPricesApi(payload)
    yield all([put(actions.priceSuccess(response))])
  } catch (error) {
    yield put(
      actions.priceError(
        typeof error === "string" ? error : languages.genericAPIError
      )
    )
  }
}

function* createConsultationFlow({
  payload,
}: Action<OpticConsultationRequest>): GFlow<ResponseCall | Message> {
  yield put(stripeActions.hasStripeError(false))
  try {
    const response: ResponseCall = yield createConsultation(payload)
    const { requires_action, payment_intent_client_secret } = response
    if (requires_action && payment_intent_client_secret)
      yield put(actions.paymentRequiresAction(payment_intent_client_secret))
    else if (response.status === "ok" && response.call) {
      yield all([put(actions.success(response.call as Call)), put(getCall())])
    } else {
      yield put(actions.error("Impossible de charger la tlc"))
    }
  } catch (error: any) {
    if (error?.code && error.code === ERROR_API.STRIPE)
      yield put(stripeActions.hasStripeError(true))

    if (error?.message) yield put(actions.error(error.message))
    else
      yield put(
        actions.error((error as string) ?? "Impossible de charger la tlc")
      )
  }
}

function* paymentWatcher(): GWatcher {
  yield takeLatest(TLC_REQUEST, createConsultationFlow)
  yield takeLatest(PRICE_REQUEST, priceFlow)
}

export default paymentWatcher
