import { EditFilled } from "@ant-design/icons"
import { Dayjs } from "dayjs"
import { chunk, cloneDeep, concat, first, indexOf } from "lodash"
import config from "react-global-configuration"

import { CONFIGURATION_SUCCESS } from "client/config/constant"
import { RESET_STORE } from "client/services/constants"
import { cdn } from "core/cdn"
import { MODIFY_RELATIVE_PAGE } from "core/constants"
import { isBirthdateBetween } from "lib/form"
import { languages } from "lib/languages"
import { DATE_FORMAT } from "lib/utils"
import { PATH } from "models/survey/_paths"
import { OPTIC_STEPS } from "models/survey/_survey"
import { FOR_PATIENT, FOR_RELATIVE, NUMBER_OF_SLOTS_AFTER_NOW, NUMBER_OF_SLOTS_BEFORE_NOW, PATH_DEEP } from "models/survey/constants"
import { surveyLanguages } from "models/survey/languages"
import { Customer, Doctor, Gender, Relative } from "types/entity"
import { Configuration, CustomerType, QuestionLanguages } from "types/props"
import { Action, Message } from "types/redux"
import { SurveyStore } from "types/store"

import { ACTION, Answer, Question, Reply, Value } from "../types"
import { get_complete_path, getSlotsReplies, is_deep_path, recursive_get, replace_question, update_answers, update_path } from "../utils"
import { CREATE_STEP, INIT_SURVEY, NEXT_STEP, PREVIOUS_STEP, REMOVE_STEP, RESET_SURVEY_REPLY, RESET_SURVEY_STORE, SET_CURRENT_STEP, SET_CUSTOMER_TYPE, SET_STEP, STEP_DOCTORS_CHOICE, STEP_PATIENT_CHOICE, STEP_RELATIVES_CHOICE, STEP_SLOTS_CHOICE, SUBMIT_SURVEY_ERROR, SUBMIT_SURVEY_REQUEST, SUBMIT_SURVEY_SUCCESS, UNSET_STEP, UPDATE_TIMELINE } from "./constants"

/*
 * function set(), unset(), next(), previous(), cancel(), finish(), create(), get(),
 * create question regarding job side [slots(), relatives(), doctors()]
 *
 *
 */
const MANAGER: Partial<SurveyStore> = {
  questions: OPTIC_STEPS,
  current: undefined,
  path: undefined,
  status: "on_going",
  answers: [],
}

/*
 * init question regarding the path
 */

const reset = (manager: Partial<SurveyStore>): Partial<SurveyStore> => {
  return init({ ...manager, answers: [] })
}
const init = (manager = MANAGER): Partial<SurveyStore> => {
  const parentIds: string[] = (manager.questions as Question[]).map(
    (question) => {
      return question.id
    }
  )
  const current = first(manager.questions)
  return {
    ...manager,
    status: "on_going",
    path: parentIds,
    current,
  } as SurveyStore
}

const reducer = (
  state = init(MANAGER),
  action: Action<
    | Message
    | Doctor[]
    | Relative[]
    | Customer
    | Question
    | Configuration
    | { replies: Reply[]; id: PATH }
    | string
    | {
      question: Question
      options?: { after?: PATH; before?: PATH }
    }
  >
): Partial<SurveyStore> => {
  const _path: string[] = state.path || ["unknow-current"]
  const _questions: Question[] = state.questions as Question[]
  const _answers: Answer[] = state.answers || []
  const _current: Question | Partial<Question> = state.current || {
    id: PATH.unknown_path,
    subquestion: undefined,
  }
  const payload = action.payload

  const configuration = (config: Configuration) => {
    return init({
      ...state,
      questions: OPTIC_STEPS,
    })
  }

  const customer = (customer: CustomerType) => {
    const new_questions = _questions.map((question): Question => {
      const dictionary: QuestionLanguages = surveyLanguages.find(
        ({ id }) => id === question.id
      ) as QuestionLanguages

      if (!dictionary) return { ...question }

      let questionLanguages = null

      if (customer === "relative_male") {
        questionLanguages = dictionary?.relative?.male
      } else if (customer === "relative_female") {
        questionLanguages = dictionary?.relative?.female
      } else {
        questionLanguages = dictionary.patient
      }

      const title = (questionLanguages?.title ?? question.title) as string

      const titleDefaultReply = (questionLanguages?.defaultReply ??
        question.defaultReply?.title) as string

      const defaultReply = question.defaultReply && {
        ...question.defaultReply,
        title: titleDefaultReply,
      }

      return {
        ...question,
        defaultReply,
        title,
      } as Question
    })

    return { ...state, questions: new_questions }
  }
  /*
   * get question from pathid
   */
  const get = (path_id: string): Question | undefined => {
    const complete_path = get_complete_path(path_id)
    const question: Question | undefined = _questions.find(({ id }) =>
      complete_path.includes(id)
    )
    if (complete_path.length === 1) return question
    return question ? recursive_get(question, complete_path) : undefined
  }

  /*
   * init question regarding the path
   */
  const set = (replies: Reply[], currentId: string): Partial<SurveyStore> => {
    let answers: Answer[] = cloneDeep<Answer[]>(_answers)

    const freshValues: Value[] = replies.map(({ value }) => value)
    answers = update_answers(answers, {
      questionId: currentId,
      value: freshValues,
    })

    return {
      ...state,
      answers,
      path: update_path(
        replies as Reply[],
        _path as string[],
        _current as Question
      ),
    } as SurveyStore
  }

  /*
   * Unset question regarding the path
   */
  const unset = (
    unReplies: Reply[] | undefined,
    currentId: PATH
  ): Partial<SurveyStore> => {
    if (!unReplies) {
      const updatedAnswers = _answers.map((answer: Answer) => {
        if (currentId === answer.questionId)
          return {
            ...answer,
            value: [],
          }
        else return answer
      })
      return { ...state, answers: updatedAnswers }
    }

    const toRemove: Value[] = unReplies.map(({ value }) => value)

    const answers: Answer[] = cloneDeep<Answer[]>(_answers).map(
      ({ questionId, value }) => {
        if (questionId === currentId) {
          return {
            questionId,
            value: value?.filter((v) => !toRemove.includes(v)) || [],
          }
        } else return { questionId, value }
      }
    )

    const path = cloneDeep<string[]>(_path)

    unReplies.forEach((reply) => {
      if (
        reply.action === ACTION.subquestion &&
        _current?.subquestion?.id &&
        path.includes(_current?.subquestion?.id)
      )
        path.splice(path.indexOf(_current.subquestion.id), 1)
    })

    return { ...state, answers, path }
  }

  /*
   * Next question regarding the path
   */
  const next = (): Partial<SurveyStore> => {
    const indexOfCurrent = _path.indexOf(_current.id || "")
    const next: string | undefined = _path[indexOfCurrent + 1]
    const current = next ? get(next) : undefined
    if (current) return { ...state, current }
    return finish()
  }

  /*
   * Retourne la question d'avant
   */
  const previous = (): Partial<SurveyStore> => {
    if (state.status === "finish")
      return { ...state, success: false, status: "on_going" }

    const indexOfCurrent = _path.indexOf(_current.id || "")

    const newPath = _path.filter(
      (p) => p !== _current.id || !_current.id.includes(PATH_DEEP)
    )

    const newAnswers = _answers.filter(
      (answer) => _current.id !== answer.questionId
    )

    const previous: string | undefined = _path[indexOfCurrent - 1]

    const newCurrent = previous ? get(previous) : undefined

    if (newCurrent)
      return {
        ...state,
        current: newCurrent,
        path: newPath,
        answers: newAnswers,
      }
    return cancel()
  }

  type TimelineStep = "initialize" | "anamnesis"

  const updateImageTimeline = (step: TimelineStep) => {
    const question: Question = get(PATH[`timeline_${step}`])
    question.imgUrl = cdn(`/images/optic/timeline/nidek/frise_step_${step}.svg`)
    return create({ question })
  }

  /*
   * Change les images de la timeline pour Nidek
   */
  const updateTimeline = () => {
    let updatedState = {}
    let timelineStep = ["initialize", "anamnesis"]
    updatedState = timelineStep.reduce((state, timelineKey: TimelineStep) => {
      return { ...updateImageTimeline(timelineKey) }
    }, {})

    return updateImageTimeline("initialize")
  }

  /*
   * Créer la question "quel proche ?"
   */
  const relatives = ({
    relatives,
  }: {
    relatives: Relative[]
  }): Partial<SurveyStore> => {
    const relativeQuestion: Question = get(PATH.relative) as Question

    if (!relatives.length) {
      relativeQuestion.replies = [
        {
          title: languages.noRelativesYet,
          value: false,
          imgUrl: cdn("images/unknown_people.svg"),
          action: ACTION.nothing,
          ui: {
            type: "ghost",
          },
        },
      ]
    } else {
      relativeQuestion.replies = relatives.map((r: Relative) => {
        const hasValidBirthdate = isBirthdateBetween(
          r.birthdate,
          config.get("validator.age.minimum"),
          config.get("validator.age.maximum")
        )
        const reply: Reply = {
          title: `${r.gender === Gender.MALE
            ? "♂"
            : r.gender === Gender.FEMALE
              ? "♀"
              : ""
            } ${r.firstname} ${r.lastname} (${(r.birthdate as Dayjs).format(
              DATE_FORMAT
            )})`,
          value: hasValidBirthdate ? r.id : "relative_too_young",
          action: hasValidBirthdate ? ACTION.default : ACTION.inability,
          additionalActions: [
            {
              value: `${MODIFY_RELATIVE_PAGE}/${r.id}`,
              title: "Modifier le proche",
              icon: <EditFilled />,
              action: ACTION.redirect,
              ui: { view: "icon" },
            },
          ],
        }

        return reply
      })
    }
    return create({ question: relativeQuestion })
  }
  /*
   * Créer la question docteur
   */
  const doctors = ({
    doctors,
  }: {
    doctors: Doctor[]
  }): Partial<SurveyStore> => {
    const doctorQuestion: Question = get(PATH.doctor) as Question

    doctorQuestion.replies = doctors.map((doctor) => {
      return {
        value: doctor.id,
        title: `Dr. ${doctor.lastname}`,
        action: ACTION.subquestion,
      }
    })
    return create({ question: doctorQuestion })
  }
  /*
   * Créer la question parent
   */
  const patient = ({
    patient,
  }: {
    patient: Customer
  }): Partial<SurveyStore> => {
    const patientQuestion: Question = get(PATH.patient) as Question

    patientQuestion.replies = [
      {
        imgUrl: cdn("/images/people_picto.svg"),
        title: `${languages.forMe}<br />(${patient.firstname} ${patient.lastname})`,
        value: FOR_PATIENT,
        ui: { type: "primary" },
      },
      {
        imgUrl: cdn("/images/proche_picto.svg"),
        value: FOR_RELATIVE,
        title: `${languages.forRelative}`,
        action: ACTION.subquestion,
      },
    ]

    return create({ question: patientQuestion })
  }
  /*
   * Créer la question slot
   */
  const slots = (): Partial<SurveyStore> => {
    const slotsQuestion: Question = get(PATH.slot) as Question

    slotsQuestion.replies = getSlotsReplies(
      NUMBER_OF_SLOTS_BEFORE_NOW,
      NUMBER_OF_SLOTS_AFTER_NOW
    )

    return create({ question: slotsQuestion })
  }

  /* Supprime une question
   * uniquement une question parent
   * par exemple pregnant , pour un patient courant HOMME
   * on supprimera la question pregnant
   */
  const remove = (pathToRemove: string) => {
    const questions = _questions.filter(
      ({ id }: { id: string }) => id !== pathToRemove
    )
    const path = _path.filter((keyPath) => keyPath !== pathToRemove)
    return {
      ...state,
      path,
      questions,
    }
  }

  /* Création de question (ou remplacement d'une question existante)
   * Pour l'instant ne créé qu'une question parent ou sous question parent
   * Il remplace la question dans le store si jamais la question.id existait déjà
   * impossible d'ajouter par exemple patient:relative:old
   * on peut modifier patient:relative pour lui donner :old, par contre
   */

  const create = ({
    question,
    options,
  }: {
    question: Question
    options?: { before?: PATH; after?: PATH }
  }): Partial<SurveyStore> => {
    const exists = get(question.id)
    if (exists) {
      return { ...state, questions: replace_question(_questions, question) }
    }
    if (is_deep_path(question.id)) {
      // Création d'une sous question
      return {
        ...state,
        questions: _questions.map((q) => {
          if (question.id.includes(q.id)) q.subquestion = question
          return q
        }),
      }
    }
    // Création d'une question.
    const questionsUpdated = [..._questions, question]
    if (options) {
      if (options.before) {
        const indexOfQuestionNext = indexOf(_path, options.before)
        const pathUpdated = concat(
          _path.slice(0, indexOfQuestionNext),
          [question.id],
          _path.slice(indexOfQuestionNext)
        )
        return { ...state, questions: questionsUpdated, path: pathUpdated }
      }
      if (options.after) {
        // @TODO but unused for now
        // const indexOfQuestionPrevious
        return { ...state, questions: questionsUpdated }
      }
    }
  }

  const finish = (): Partial<SurveyStore> => {
    return { ...state, status: "finish" }
  }
  const cancel = (): Partial<SurveyStore> => {
    return { ...state, status: "cancel" }
  }

  const _id: PATH = state.current?.id || PATH.unknown_path

  switch (action.type) {
    case SET_CURRENT_STEP:
      return {
        ...state,
        current: _questions.find(({ id }) => id === payload),
      }
    case UPDATE_TIMELINE:
      return updateTimeline()
    case STEP_PATIENT_CHOICE:
      return patient({ patient: payload as Customer })
    case STEP_DOCTORS_CHOICE:
      return doctors({ doctors: payload as Doctor[] })
    case STEP_RELATIVES_CHOICE:
      return relatives({ relatives: payload as Relative[] })
    case STEP_SLOTS_CHOICE:
      return slots()
    case CREATE_STEP:
      return create(
        payload as {
          question: Question
          options?: { after?: PATH; before?: PATH }
        }
      )
    case UNSET_STEP:
      return unset(
        (payload as { replies?: Reply[] }).replies,
        (payload as { id?: PATH }).id ?? _id
      )
    case SET_STEP:
      return set(
        (payload as { replies?: Reply[] }).replies,
        (payload as { id?: PATH }).id ?? _id
      )
    case REMOVE_STEP:
      return remove(payload as string)
    case NEXT_STEP:
      return next()
    case PREVIOUS_STEP:
      return previous()
    case INIT_SURVEY:
      return init({ ...state } as Partial<SurveyStore>)
    case SET_CUSTOMER_TYPE:
      return customer(payload as CustomerType)
    case CONFIGURATION_SUCCESS:
      return configuration(payload as Configuration)
    case RESET_SURVEY_STORE:
    case RESET_STORE:
      return reset({ ...state } as Partial<SurveyStore>)
    case RESET_SURVEY_REPLY:
      return {
        ...state,
        answers: _answers.filter(
          (answer) => answer.questionId !== action.payload
        ),
      }
    case SUBMIT_SURVEY_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case SUBMIT_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      }
    case SUBMIT_SURVEY_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload as Message,
      }
    default:
      return state
  }
}
export default reducer
